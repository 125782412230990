import React, { Component } from "react";
import MyContext from "../context/myContext";
import { changeLang } from "../../reducers/actions/index";
import "./embedded.scss";
import Translation from "../translation/translation";
import StrsEmbed from "../embed/embed";
import { connect } from "react-redux";
import config from "../embed/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import { navigate } from "gatsby-link";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class Embedded extends Component {
  intervalId;
  timeoutPolling;
  constructor(props, context) {
    super(props, context);
    const clientSide = typeof window !== "undefined";
    let showFilters = false;
    if (clientSide) {
      const region = localStorage.getItem("qvax-selected-region");
      showFilters = region === "Wallonie";
    }

    this.state = {
      clientSide,
      embedded: config,
      selectedStateIndex: undefined,
      showFilters: false,
      loading: true,
      showBannerUserUnknown: false,
      showTitle: true,
      showEmbedded: false,
      pollError: false,
      showJnjBanner: false,
      states: [
        { value: "Hainaut", selected: false },
        { value: "Brabant-Wallon", selected: false },
        { value: "Namur", selected: false },
        { value: "Liège", selected: false },
        { value: "Luxembourg", selected: false },
      ],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const config = this.state.embedded;
    if (config) {
      config.embedded.locale = nextProps.lang;
    }
    this.setState(
      {
        embedded: config,
      },
      () => {
        this.resetEmbedded(config);
      }
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved);
    }
  }

  componentDidMount() {
    if (window.location.hash.includes("wish-lists")) {
      this.setState({ showTitle: false });
    } else {
      this.setState({ showTitle: true });
    }
    if (typeof window !== "undefined") {
      window.addEventListener("hashchange", () => {
        if (window.location.hash.includes("wish-lists")) {
          this.setState({ showTitle: false });
        } else {
          this.setState({ showTitle: true });
        }
      });
      if (localStorage.getItem("SeatersEmbeddedAuthToken")) {
        this.intervalId = setInterval(() => this.checkSession(), 300000);
      }
    }
    this.pollOnCustomInfo(3000, 20);
  }

  render() {
    return (
      <main id="main">
        {!this.state.pollError && (
          <div className="titles">
            {this.state.loading && this.state.showTitle && (
              <h1>
                <Translation trl="embed_polling" />
              </h1>
            )}
            {!this.state.loading && this.state.showTitle && (
              <h1>
                <Translation trl="please_select" />
              </h1>
            )}
          </div>
        )}

        {/* Banner : not available for 12-15 yrs */}
        {(this.state.showReactivateBanner) && (
          <div className="jnj">
            {this.state.showReactivateBanner && (
              <p>
                <Translation trl="reactivate_go_profile" />
              </p>
            )}
            <br />
            {/* {this.state.showJnjBanner && (
              <p>
                <Translation trl="jnj_go_profile" />
              </p>
            )} */}
            {/* <button
              onClick={() =>
                navigate("/profile", { state: { lang: this.props.lang } })
              }
            >
              <Translation trl="jnj_go_profile_button" />
            </button> */}
          </div>
        )}
        {this.state.showBannerUserUnknown && (
          <p className="error error-registerNational">
            <Translation trl="error_registerNational"  />   
          </p>
        )}
        {this.state.pollError && (
          <p className="error">
            <Translation trl="error_polling" />
          </p>
        )}
        {!this.state.pollError && (
          <div id="embed" className="embed">
            {this.state.loading && (
              <div className="progress_container">
                <CircularProgress size={100} />
              </div>
            )}
            {this.state.showEmbedded && (
              <StrsEmbed
                onEmbeddedLoaded={() => this.onEmbeddedLoaded()}
                lang={this.props.lang}
                region={this.props.region}
              />
            )}
          </div>
        )}
      </main>
    );
  }

  checkSession() {
    const session = JSON.parse(
      localStorage.getItem("SeatersEmbeddedAuthToken")
    );
    if (session) {
      const expireDate = new Date(session.expiresOn);
      const dateNow = new Date();
      if (expireDate - dateNow <= 300000) {
        clearInterval(this.intervalId);
        localStorage.removeItem("SeatersEmbeddedAuthToken");
        navigate("/login", { state: { lang: this.props.lang } });
      }
    }
  }

  pollOnCustomInfo(interval, maxAttempts) {
    let attempts = 0;
    if (typeof window !== "undefined") {
      if (localStorage.getItem("SeatersEmbeddedAuthToken")) {
        const parsedSession = JSON.parse(
          localStorage.getItem("SeatersEmbeddedAuthToken")
        );
        const session = {
          expirationDate: parsedSession.expiresOn,
          token: parsedSession.token,
        };
        if (this.isSessionValid(parsedSession)) {
          this.context.sdkClient.sessionService.setSession(session);
          this.context.sdkClient.fanService.fan().then(fan => {
            return;
          });
        }
      }
    }

    const calculateAge = birthDate => {
      var diff_ms = Date.now() - new Date(birthDate).getTime();
      var age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970);
    };

    const getCustomField = (customInfos, key) => {
      for (let info of customInfos) {
        if (info.field.key === key) {
          return info;
        }
      }
      return null;
    };

    const executePoll = async (resolve, reject) => {
      const custom = await this.context.sdkClient.fanService.customProfile(FG_ID);
      

      attempts++

      const smallsvaccinationservicecalled = getCustomField(custom,"smallsvaccinationservicecalled");
      const smallsvaccinationmanufactureragreement = getCustomField(custom,"smallsvaccinationmanufactureragreement");
      const smallsvaccinationbirthdate = getCustomField(custom,"smallsvaccinationbirthdate");
      const smallsvaccinationcodestatus = getCustomField(custom,"smallsvaccinationcodestatus");

      let idNumber
      if (localStorage.getItem("SeatersEmbeddedAuthToken")) {
        idNumber = JSON.parse(localStorage.getItem("SeatersEmbeddedAuthToken")).identity.email;
      }

      const agreementAnswered = localStorage.getItem("qvax-jnj-agreement") && idNumber && atob(localStorage.getItem("qvax-jnj-agreement")) === idNumber;
   
          ////////////////// TRUE
      if (smallsvaccinationservicecalled && smallsvaccinationservicecalled.value === "true") {
        if (smallsvaccinationbirthdate && smallsvaccinationbirthdate.value) {

          const age = calculateAge(smallsvaccinationbirthdate.value);
          const showJnjBanner = age < 41 && age >= 18 && !smallsvaccinationmanufactureragreement.value && !agreementAnswered;
          const showReactivateBanner = smallsvaccinationcodestatus.value === "DEACTIVATED" && !this.props.registerReactivateChecked;

          this.setState({
            showEmbedded: true,
            showJnjBanner: age >= 18 && age < 41,
            showReactivateBanner: showReactivateBanner,
          });

        } else {
          this.setState({ showEmbedded: true })
          if (this.props.registerReactivateChecked) {
            this.context.sdkClient.fanService.reactivateDoclrUser(FG_ID,null)
          }
        }
        return resolve(custom)
      } 


      /////////// false

      if (smallsvaccinationservicecalled && smallsvaccinationservicecalled.value === "false") {
          this.setState({ pollError: true })
          return clearTimeout(this.timeoutInterval);
      }
      
      /////////// false_user_unknown

      if (smallsvaccinationservicecalled && smallsvaccinationservicecalled.value === "false_user_unknown") {
        this.setState({ showBannerUserUnknown: true, loading: false })
        return clearTimeout(this.timeoutInterval);
      }

    if ((smallsvaccinationservicecalled && smallsvaccinationservicecalled.value === null) && attempts === 60){
      this.setState({ pollError: true })
      return clearTimeout(this.timeoutInterval);
    }

      
      /////////////////// null
      if (smallsvaccinationservicecalled && smallsvaccinationservicecalled.value === null){
        return this.timeoutInterval = setTimeout(executePoll, 1000);
      }
    }


    return new Promise(executePoll);
  }

  onEmbeddedLoaded() {
    this.setState({ loading: false });
  }

  resetEmbedded(config) {
    const element = document.getElementById("seaters");
    if (element) {
      element.parentNode.removeChild(element);
      this.setState({ embedded: config }, () => {
        var newElement = document.createElement("div");
        newElement.setAttribute("id", "seaters");
        var p = document.getElementById("embed");
        p.appendChild(newElement);
        if (typeof window !== "undefined") {
          window.Seaters.embed("seaters", config.embedded);
        }
      });
    }
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn);
    const dateNow = new Date();
    return expireDate > dateNow;
  }
}

Embedded.contextType = MyContext;
const mapStateToProps = state => ({
  lang: state.lang,
  region: state.region,
});

const mapDispatchToProps = () => {
  return {
    changeLang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(Embedded);
