import React, { Component } from "react";
import ContextProvider from "../components/context/contextProvider";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducer from "../reducers";
import Embedded from "../components/embedded/embedded";
import "../styles/shared.scss";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/Navbar";
import { navigate } from "gatsby";
import Cookies from "../components/cookies/cookies";
import MessageAlert from "../components/message-alert/message-alert";

let store;
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(allReducer);
}

class Index extends Component {
  constructor(props) {
    super(props);
    if (typeof window !== "undefined") {
      const session = localStorage.getItem("SeatersEmbeddedAuthToken");
      if (!session) {
        if (!localStorage.getItem("qvax-selected-region")) {
          navigate("/region");
        }
        if (!localStorage.getItem("qvax-howitworks-accepted")) {
          navigate("/how");
        } else {
          navigate("/login");
        }
      } else {
        const parsedSession = JSON.parse(session);
        if (!this.isSessionValid(parsedSession)) {
          navigate("/login");
        }
      }
    }
  }

  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <Navbar logout={true} />
          <Embedded
            langSaved={
              this.props.location.state ? this.props.location.state.lang : null
            }
          />
          <Footer />
        </Provider>
      </ContextProvider>
    );
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn);
    const dateNow = new Date();
    return expireDate > dateNow;
  }
}
export default Index;
