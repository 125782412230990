import React, { useEffect } from "react";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL, EMBEDDED_URL_DOMAIN } from '../../constants/global';
import config from "./config";
const StrsEmbed = props => {
  useEffect(() => {
    const strs_script = document.createElement("script")
    strs_script.src = EMBEDDED_URL;
    strs_script.async = true;
    document.body.appendChild(strs_script)

    const strs_lang1 = document.createElement("script")
    strs_lang1.src = EMBEDDED_URL_DOMAIN + "seaters-embedded.locale-en.bundle.js"
    strs_lang1.async = true
    document.body.appendChild(strs_lang1)

    const strs_lang2 = document.createElement("script")
    strs_lang2.src = EMBEDDED_URL_DOMAIN + "seaters-embedded.locale-fr.bundle.js"
    strs_lang2.async = true
    document.body.appendChild(strs_lang2)

    const strs_lang3 = document.createElement("script")
    strs_lang3.src = EMBEDDED_URL_DOMAIN + "seaters-embedded.locale-nl.bundle.js"
    strs_lang3.async = true
    document.body.appendChild(strs_lang3)

    const strs_lang4 = document.createElement("script")
    strs_lang4.src = EMBEDDED_URL_DOMAIN + "seaters-embedded.locale-de.bundle.js"
    strs_lang4.async = true
    document.body.appendChild(strs_lang4)

    strs_script.onload = () => {
      config.embedded.locale = props.lang
      props.onEmbeddedLoaded()
      if (typeof window !== "undefined") {
        window.Seaters.embed("seaters", config.embedded)
      }
    }
  }, [])
  return <div id="seaters" />
}

export default StrsEmbed
