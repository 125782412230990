import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

export default {
  embedded: {
    fanGroups: [
      {
        fanGroupId: FG_ID,
      },
    ],
    theme: {
      direction: "ltr",
      showHeader: false,
      showFooter: false,
      showCookies: false,
      palette: {
        backgroundColor: "transparent",
        baseColor: "#1c6bb0",
        secondaryColor: "#1c6bb0",
        textColor: "#30323b",
        border: false,
        wlListBackgroundColor: "transparent",
        menuItemsBgColor: "transparent",
        headerTextColor: "grey",
        showHome: false,
      },
      layout: {
        wlDetails: "card",
        sizing: {
          xsmall: {
            pagination: {
              maxPageSize: 20,
              startPage: 1,
            },
            wishListsGridLayout: {
              columns: 1,
              layout: "full",
              spacing: 8,
            },
            detailsPageWidth: "100%",
          },
          small: {
            pagination: {
              maxPageSize: 90,
              startPage: 1,
            },
            wishListsGridLayout: {
              columns: 1,
              layout: "full",
              spacing: 16,
            },
            detailsPageWidth: "100%",
          },
          medium: {
            pagination: {
              maxPageSize: 90,
              startPage: 1,
            },
            wishListsGridLayout: {
              columns: 1,
              layout: "corona",
              spacing: 8,
            },
            detailsPageWidth: "90%",
          },
          large: {
            pagination: {
              maxPageSize: 90,
              startPage: 1,
            },
            wishListsGridLayout: {
              columns: 1,
              layout: "corona",
              spacing: 8,
            },
            detailsPageWidth: "90%",
          },
          xlarge: {
            pagination: {
              maxPageSize: 90,
              startPage: 1,
            },
            wishListsGridLayout: {
              columns: 1,
              layout: "corona",
              spacing: 8,
            },
            detailsPageWidth: "70%",
          },
        },
      },
    },
    user: {
      countryCode: "BE",
    },
    locale: "fr",
    metadata: {
      origin: "seaters-embedded",
      validateEmailRedirectUrl: "https://audifin-rewards.be",
    },
    deepLinking: {
      routerType: "history",
    },
  },
}
